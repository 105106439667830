import React from 'react';
import styles from './App.module.css'

const Section = ({ content }) => {
  
  let paragraphs;

  if (typeof content === 'object') {
    paragraphs = content;
  } else if (typeof content === 'string') {
    paragraphs = content.split('\n').map((line, index) => <p key={index}>{line}</p>);
  } else {
    paragraphs = '';
  }

  return (
    <div className={styles.sectioncontent}>{paragraphs}</div>
  )
}

export default Section;
