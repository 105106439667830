import React, { useState, useEffect, useRef } from 'react';
import styles from './App.module.css';
import Section from './Section';

const NavBar = ({ sections, onSectionClick }) => {
  const navbarRef = useRef();
 
  const [cellRefs, setCellRefs] = useState([]);

  const [activeSection, setActiveSection] = useState(null);
  const [activeOverlayStyle, setActiveOverlayStyle] = useState({});  // New state variable
  const [textHolderStyle, setTextHolderStyle] = useState({});
  const [cellOpen, setCellOpen] = useState(false);
  const [originalCellStyle, setOriginalCellStyle]  = useState({});
  const [activeCoverImage, setActiveCoverImage] = useState({})

  const animationtimer = 0.5;


  useEffect(() => {
    setCellRefs(sections.map(() => React.createRef()));
  }, [sections]);

  const handleResize = () => {
    // Get the dimensions of .navbar
    const navbarRect = navbarRef.current.getBoundingClientRect();
    

    // Set the size of .textholder to match .navbar
    setTextHolderStyle({
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: `${navbarRect.width}px`,
      height: `${navbarRect.height}px`, 
      backgroundColor:"none",
      zIndex:"10"

    });
  };

  const handleClick = (section, event, color, coverimage) => {
    if (!activeSection) {

      console.log(section.title)
      
      const index = sections.indexOf(section);
      const menuRect = cellRefs[index].current.getBoundingClientRect();

      setActiveSection(section);
      onSectionClick(section);



      const rect = event.currentTarget.getBoundingClientRect();
      const parentRect = event.currentTarget.parentNode.getBoundingClientRect();

      // Calculate the initial position and size in percentages
      const top = ((rect.top - parentRect.top) / parentRect.height) * 100;
      const left = ((rect.left - parentRect.left) / parentRect.width) * 100;
      const width = (rect.width / parentRect.width) * 100;
      const height = (rect.height / parentRect.height) * 100;

      const menuTop = ((menuRect.top - parentRect.top) / parentRect.height) * 100;
      const menuleft = ((menuRect.left - parentRect.left) / parentRect.width) * 100;
      const menuWidth = (menuRect.width / parentRect.width) * 100;
      const menuHeight = (menuRect.height / parentRect.height) * 100;

      console.log(menuRect.width)

      setActiveCoverImage ({
        position: 'absolute',
        top: `0px`,
        left: "0px",
        width: `${menuRect.width}px`,
        height: `${menuRect.height}px`, 
        backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"  ,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
  
      });
      
      setActiveOverlayStyle({
        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundColor: color,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
      //  backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
      //  backgroundSize: "contain",
      //  backgroundRepeat: "no-repeat",
      //  backgroundPosition: "center"  ,
      
     
       
      });

   


      setOriginalCellStyle({

        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundColor: color,
       
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
       // backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
       // backgroundSize: "contain",
       // backgroundRepeat: "no-repeat",
      //  backgroundPosition: "center"  ,
        
      })
   

      // Call handleResize to set the size of .textholder
      handleResize();
      setCellOpen(true)
    }
  };

  // Add a window resize listener to call handleResize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose =() =>{
    setCellOpen(false)
    
    //setActiveSection(null)
     // Clear the overlay style
    onSectionClick(null);
  
  };

  // Trigger the transition to the final position, size, and transform after the overlay has been rendered
  useEffect(() => {
    if (cellOpen === true) {
      setActiveOverlayStyle({
        ...activeOverlayStyle,  // Keep the existing properties
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        transform: 'translate(0, 0) scale(1, 1)',
        zIndex: "4",
        
      })
    } else {
     
      setActiveOverlayStyle({
        ...activeOverlayStyle,
        ...originalCellStyle, // Keep the existing properties
      });
  
      const timer = setTimeout(() => {
        setActiveSection(null);
      }, 500)
  
      return () => {
        clearTimeout(timer); // This clears the timer if the component is unmounted or cellOpen changes
      };
    }
  }, [cellOpen]);



  return (
    <div className={styles.navbar} ref={navbarRef}>
      <div className={styles.navlist}>
        {/* Render the new overlay div here, as a direct child of the grid container */}
        {activeSection && (<>
            <div
              className={styles.activeOverlay}
              style={activeOverlayStyle}
            >
      
           
            
           <div 
           className={styles.textholder} 
           style={textHolderStyle}
            >   
            <div className={`${cellOpen === false ? styles.fadeintext : styles.fadeouttext}`} style={{...activeCoverImage}}></div>
             
          
              {activeSection.title}<br />
              
              <div className={`${cellOpen === true ? styles.fadeintext : styles.fadeouttext}`} >
                
                <Section
                
                content={activeSection.content}
                />
                
              <br />
              <p 
                onClick={(event) => {
                  event.preventDefault();
                  handleClose();
                }}
              >
                close
              </p></div> </div></div>
              </>
          )}

        {/* Render the grid cells */}
        {sections.map((section, index) => {
          
      

          const size = section["2015"][0].size; // Get the corresponding size
          
          const color = section["2015"][0].color
          const coverimage = section["2015"][0].image
          
    
         
         
          return (

        
            <span 
            ref={cellRefs[index]}
             style={{
              backgroundColor: color, 
              backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
              backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
           
              backgroundPosition: "center"  }}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                handleClick(section, event, color, coverimage);
              }}
              key={section.title}
              className={`${styles.menuitem} ${styles[size]} ${
                activeSection && activeSection === section ? styles.active : ''
              }`} // Add the size class here
            >

              <a>
              
                {section.title}
               
                
              </a>
            </span>
           
          );
        })}
      </div>
    </div>
  );
};

export default NavBar;
