import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer style={{backgroundColor: '#0099cc', color: '#fff', textAlign: 'center', padding: '10px 0', marginTop: '20px'}}>
      <p>&copy; {year} Dennis Karlsson. All rights reserved.</p>
    </footer>
  )
}

export default Footer;