import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';
import Sidebar from './Sidebar';
import Section from '../globalfunctions/Section';


function Content() {

  const { sections, handleNavbarClick } = useContext(SectionsContext);

  return (
    
    <div className={styles.content}>
     
    <Sidebar sections={sections} />
    <div className={styles.sectionholder}>
    <div className={styles.section}>
           <Routes>
             {sections.map((section, index) => (
               <Route 
                 path={`/${section.title.toLowerCase().replace(/ /g, '-')}`} 
                 key={index} 
                 element={<Section title={section.title} content={section.content} />} 
               />
             ))}
             <Route
               path=''
               key={"default"} 
               element={<Section title={sections[0].title} content={sections[0].content} />}
             />
           </Routes>
          
           </div></div>
    </div>
    
  );
}

export default Content;
