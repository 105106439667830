import React from 'react';

const Footer = () => {
  return (
    <footer style={{color: 'purple', fontSize: '20px', fontFamily: 'Comic Sans MS'}}>
      copyright 1995 Dennis Karlsson
    </footer>
  )
}

export default Footer;
