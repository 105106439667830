import React from 'react';
import myImage from './portrait.gif';
import styles from './App.module.css'

const Header = () => {
  const skills = [
    'REACT', 'JS', 'JQUERY', 'NodeJS', 'HTML5', 'WordPress', 'CSS', 'VBA', 
    'Python', 'C++', 'SQL', 'MSSQL', 'Stored Procedures', 'Blockchain technology', 
    'Payment Solutions (XRPL)', 'API integrations', 'SCRUM', 'Team leading', 
    'Recruitment', 'Planning', 'Performance Analysis', 'Staff Training & Onboarding', 
    'Reporting', 'Photography', 'Studio Lighting', 'Post Processing', 'Photoshop', 
    'Lightroom', 'AfterEffects', 'InDesign', 'Color profiling for print & web', 
    'Marketing'
  ];

  return (
    <>
    <div className={styles.header}>
      <img src={myImage} alt="Your Photo" style={{ height: '150px' }} />
      <div className={styles.name}>Dennis Karlsson</div>
      
  
    </div>

    </>
  )
}

export default Header;
