import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import SectionsContext from './SectionsContext';
import styles from './App.module.css';
import data from '../data/data.json';
import Content from './Content';



const { sections } = data[0];

function App() {
  const [activeSection, setActiveSection] = useState(sections[0]);

  const handleNavbarClick = (section) => {
    setActiveSection(section);
  };

  const appRef = useRef(null); // Create a ref

  // Use the helper function, passing the ref


  return (
    <SectionsContext.Provider value={{ sections, activeSection, handleNavbarClick }}>
        <div className={styles.app} ref={appRef}> {/* Attach the ref here */}
            <Router>
            <h1 className={styles.headerh1}>THE FUTURE</h1>
                <div className={styles.contentWrapper}>
                    <Content />
                </div>
            </Router>
        </div>
    </SectionsContext.Provider>
  );
}

export default App;