import React from 'react';
import myImage from './portrait.gif';

const Header = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td style={{textAlign: 'center'}}>
            <h1 style={{color: 'black', fontSize: '48px', fontFamily: 'Comic Sans MS'}}>Dennis Karlsson</h1>
            <img src={myImage} alt="Your Photo"/>
          </td>
          
        </tr>
      </tbody>
    </table>
  )
}

export default Header;
