
import React, { useContext } from 'react';
import ProfilePhoto from './ProfilePhoto';
import Navbar from './Navbar';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';

function Header() {
  const { sections, handleNavbarClick } = useContext(SectionsContext);

  return (
    <header className={styles.header}>
      <ProfilePhoto />
      <h1 className={styles.title}>Dennis Karlsson</h1>
      <Navbar sections={sections} onNavbarClick={handleNavbarClick} />
    </header>
  );
}

export default Header;
