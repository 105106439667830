
import React from 'react';
import styles from './App.module.css';

function Sidebar() {
  const skills = [
    'REACT', 'JS', 'JQUERY', 'NodeJS', 'HTML5', 'WordPress', 'CSS', 'VBA', 
    'Python', 'C++', 'SQL', 'MSSQL', 'Stored Procedures', 'Blockchain technology', 
    'Payment Solutions (XRPL)', 'API integrations', 'SCRUM', 'Team leading', 
    'Recruitment', 'Planning', 'Performance Analysis', 'Staff Training & Onboarding', 
    'Reporting', 'Photography', 'Studio Lighting', 'Post Processing', 'Photoshop', 
    'Lightroom', 'AfterEffects', 'InDesign', 'Color profiling for print & web', 
    'Marketing'
  ];

  return (
    <>
    
    <aside className={styles.sidebar}>
      <div className={styles.sidebartext}>
      <div className={styles.skillsgrid}>{skills.join(' / ')}</div>
      </div>
    </aside>
    </>
  );
}

export default Sidebar;
