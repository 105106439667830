// App.js
import React, { useState } from 'react';
import styles from './App.module.css';
import Header from './Header';
import NavBar from './NavBar';
import Section from '../globalfunctions/Section';
import Footer from './Footer';
import data from '..//data/data.json';


const { sections } = data[0];

function App() {
  const [currentSection, setCurrentSection] = useState(sections[0]);

  return (
    <div className={styles.app}> 
      <Header />
      
      <NavBar sections={sections} onSectionClick={setCurrentSection}/>

   
     
     <div className={styles.sectioncontent}>
   
      <Section 
        key={currentSection.title}
        title={currentSection.title}
        content={currentSection.content}
      />
      </div>
     
      <Footer />
    </div>
  );
}

export default App;
