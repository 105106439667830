import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';
import Sidebar from './Sidebar';
import Section from './Section';
import AIChat from './AIChat'


function Content() {

  return (
    
    <div className={styles.content}>
      

    <div className={styles.sectionholder}>

      <AIChat />
         
           </div>
    </div>
    
  );
}

export default Content;
