import React, { useState } from 'react';
import styles from './App.module.css';

const NavBar = ({ sections, onSectionClick }) => {
  const [activeSection, setActiveSection] = useState(sections[0]);

  const handleClick = (section) => {
    setActiveSection(section);
    onSectionClick(section);
  };

  return (
    <div className={styles.navbar}>
      <ul className={styles.navlist}>
        {sections.map((section) => (
          <li 
          href="#"
          onClick={(event) => {
            event.preventDefault();
            handleClick(section);
          }}
            key={section.title}
            className={`${styles.menuitem} ${
              activeSection === section ? styles.active : ''
            }`}
          >
            <a>
              {section.title.toUpperCase()}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
