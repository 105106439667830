import React from 'react';

const Banner = () => {
  const buzzwords = [
    'REACT', 'JS', 'JQUERY', 'NodeJS', 'HTML5', 'WordPress', 'CSS', 'VBA', 
    'Python', 'C++', 'SQL', 'MSSQL', 'Stored Procedures', 'Blockchain technology', 
    'Payment Solutions (XRPL)', 'API integrations', 'SCRUM', 'Team leading', 
    'Recruitment', 'Planning', 'Performance Analysis', 'Staff Training & Onboarding', 
    'Reporting', 'Photography', 'Studio Lighting', 'Post Processing', 'Photoshop', 
    'Lightroom', 'AfterEffects', 'InDesign', 'Color profiling for print & web', 
    'Marketing'
  ];

  return (
    <marquee style={{background: 'yellow', color: 'red', fontSize: '24px', fontFamily: 'Comic Sans MS'}}>
      {buzzwords.join(' / ')}
    </marquee>
  )
}

export default Banner;