import React from 'react';

const Footer = () => {
  return (
    <div style={{ background: '#333', color: '#fff', textAlign: 'center', padding: '20px' }}>
      © 2000 Dennis Karlsson
    </div>
  )
}

export default Footer;
