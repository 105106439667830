
import React from 'react';
import styles from './App.module.css';
import photo from './portrait.jpg';

function ProfilePhoto() {
  return (
    <img src={photo} alt="Profile" className={styles.profilePhoto} />
  );
}

export default ProfilePhoto;
