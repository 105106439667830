// Header.js

import React from 'react';
import styles from './App.module.css';
import ProfilePic from './ProfilePic';
import photo from './portrait.jpg';
import SkillsBanner from './SkillsBanner';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <ProfilePic src={photo} /> {/* replace with your profile picture's path */}
        <div className={styles.headerInfo}>
          <h1>Your Name</h1>
          <h2>Job Title</h2>
        </div>
      </div>
      <SkillsBanner />
    </header>
  );
};

export default Header;
