import React from 'react';


const Header = () => {
  return (
    <header className="header">
      <table className="header-table">
        <tbody>
          <tr>
            <td>
              <h1>Dennis Karlsson CV</h1>
            </td>
          </tr>
        </tbody>
      </table>
    </header>
  );
};

export default Header;