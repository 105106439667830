import React, { useEffect, useState } from 'react';
import styles from './App.module.css';

const Section = ({ title, content, typingSpeed = 5 }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const lines = content.split('\n');
    let currentIndex = 0;
    let currentLine = '';
    let animationDelay = typingSpeed;

    const typingTimeouts = [];

    lines.forEach((line, index) => {
      line.split('').forEach((char, charIndex) => {
        const typingTimeout = setTimeout(() => {
          currentLine += char;
          setDisplayText((prevText) => prevText + char);

          if (charIndex === line.length - 1 && index !== lines.length - 1) {
            currentLine = '';
            setDisplayText((prevText) => prevText + '\n');
          }

          if (charIndex === line.length - 1 && index === lines.length - 1) {
            clearTimeouts(typingTimeouts);
          }
        }, animationDelay);

        animationDelay += typingSpeed;
        typingTimeouts.push(typingTimeout);
      });
    });

    return () => {
      clearTimeouts(typingTimeouts);
    };
  }, [content, typingSpeed]);

  const clearTimeouts = (timeouts) => {
    timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  };

  return (
    <section className={styles.typewriter} style={{ padding: '20px', borderBottom: 'thin dashed #20c20e' }}>
      <h2>{title}</h2>
      <div className={styles["typewriter"]}>
        {displayText.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </section>
  );
};

export default Section;
