import React from 'react';
import Header from './Header';
import Menu from './Menu';
import Banner from './Banner';
import Section from '../globalfunctions/Section';
import Footer from './Footer';
import data from '..//data/data.json';
import styles from './App.module.css';

const { sections } = data[0];

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <br />
      <Banner />
      <br /><br />
      <Menu sections={sections} />  
      <div className={styles.paragraphs}>
      {sections.map(section => (
        <Section 
          key={section.title}
          title={section.title}
          content={section.content}
        />
      ))}
      </div>
      
      <Footer />
    </div>
  );
}

export default App;
