import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'


import App1 from './1991/App';
import App2 from './1996/App';
import App3 from './1998/App';
import App4 from './2000/App';
import App5 from './2005/App';
import App6 from './2010/App';
import App7 from './2015/App';
import App8 from './2020/App';
import App9 from './2025/App';


const Popup = ({ isFading }) => {
  return (
    <div className={`bgdiv ${isFading ? "fade-out" : ""}`}>
      <div className="popup">
      <h2>Hello.</h2>
      <p>This is my interactive resume. <br /><br />I still have some content to upload and some bugs to fix. <br />Oh, it also needs a mobile version. <b>For now, please use a desktop for best experience</b></p>
      <br /> <p>Pick your favorite internet year! <br /> <br /> <br /> <br /> <br /> <br /> <b><a className='downloadpdf' href="/DENNIS_KARLSSON_CV.pdf" download>or download CV as .pdf</a></b>
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};




const AppSelector = ({ onSelectApp, startFade, isFading }) => {


  const handleButtonClick = (app) => {
  

    onSelectApp(app);  // Set the selected app immediately
    startFade();  // Trigger the fade-out
    window.history.pushState({}, '', '/');
  };

  return (
    <div className={`navbar ${isFading ? "navbar-move" : ""}`}>
      <button onClick={() => handleButtonClick(<App1 key="1991" />)}>1991</button>
      <button onClick={() => handleButtonClick(<App2 key="1996" />)}>1996</button>
      <button onClick={() => handleButtonClick(<App3 key="1998" />)}>1998</button>
      <button onClick={() => handleButtonClick(<App4 key="2000" />)}>2000</button>
      <button onClick={() => handleButtonClick(<App5 key="2005" />)}>2005</button>
      <button onClick={() => handleButtonClick(<App6 key="2010" />)}>2010</button>
      <button onClick={() => handleButtonClick(<App7 key="2015" />)}>2015</button>
      <button onClick={() => handleButtonClick(<App8 key="2020" />)}>2020</button>
      <button onClick={() => handleButtonClick(<App9 key="2025" />)}>2025</button>
    </div>
  );
};


const AppContainer = () => {
  const [selectedApp, setSelectedApp] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [navbarAtTop, setNavbarAtTop] = useState(false);
  const [appFading, setAppFading] = useState(false);



  const handleAppSelection = (app) => {
    setAppFading(true);  // Start fading out the current app

    setTimeout(() => {
      setSelectedApp(app);  // Set the new app after fading out the current one
      setAppFading(false);  // Reset fading state to show the new app
    }, 500);  // This corresponds to the 0.5s fade-out duration
  };

  const startFadeOut = () => {
    setNavbarAtTop(true);  // Trigger the fading
    setTimeout(() => {
      setShowPopup(false); // Now, remove the Popup from DOM
    }, 1000);  // This is after the 0.5s fade-out duration
  };

  return (
    <>
      {showPopup && <Popup isFading={navbarAtTop} />}
      <AppSelector onSelectApp={handleAppSelection} startFade={startFadeOut} isFading={navbarAtTop} />
      <div className={`App App-content ${appFading ? 'fade-out' : ''}`}>{selectedApp}</div>
    </>
  );
};






const root = createRoot(document.getElementById('root'));
root.render(<AppContainer />);