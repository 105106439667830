import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './App.module.css';



function App() {
    const [query, setQuery] = useState('');
    const [uuid, setUuid] = useState(null);
    const [answer, setAnswer] = useState('');
    const [personality, setPersonality] = useState('1');  // Default to AI
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);



    // List of predefined questions
    const predefinedQuestions = [
        "When did Dennis code his first computer game?",
        "What programming languages is Dennis familiar with?",
        "What is Dennis latest project related to?"
           ];

    const handlePredefinedQuestionClick = (question) => {
        setQuery(question);
    };

    useEffect(() => {
        // Check if a UUID exists in local storage
        let userUuid = localStorage.getItem('userUuid');
        if (!userUuid) {
            userUuid = uuidv4();  // Generate a new UUID
            localStorage.setItem('userUuid', userUuid);
        }
        setUuid(userUuid);
    }, []); 

    const handleSubmit = async () => {
        setIsLoading(true);  // Start loading
        setAnswer(query + "\n\n");  // Reset the answer to display only the new question
           
        setQuery('');  // Clear the question input
        try {
            const response = await fetch('https://www.denniskarlsson.se/question.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, uuid, personality }),
            });
            if (!response.ok) {
                console.error('Response not OK:', await response.text());
                return;
            }
            const result = await response.json();
            console.log(result);
    
            if (result.message) {
                if (result.message.includes("You have reached the limit")) {
                    setErrorMessage(result.message);
                    setIsLoading(false);
                    return;  // Stop further execution
                }
            }
    
            if (response.ok) {
                pollForAnswer(uuid);
                setErrorMessage(null);
            } else {
                console.error('Failed to submit the query.');
                setIsLoading(false);  // End loading if there's an error
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);  // End loading if there's an error
        }
    };
    
    
    const pollForAnswer = async (uuid) => {
        try {
            const response = await fetch(`https://www.denniskarlsson.se/answer.php?uuid=${uuid}`);
            const result = await response.json();
            
    
            if (result.answer) { // If there's an answer
                setAnswer(prev => prev + result.answer); // Append the answer below the question
                setIsLoading(false);
                setErrorMessage(null); // Clear any previous error messages
            } else if (result.message) {
                if (result.message.includes("You have reached the limit")) {
                    setErrorMessage(result.message); // Display the server's error message
                    console.log('Error Message:', result.message); 
                    setIsLoading(false);
                } else {
                    console.log(result.message);
                    setTimeout(() => pollForAnswer(uuid), 3000); // Retry after 3 seconds if there's no answer yet
                }
            } else {
                console.error('Unexpected server response:', result);
                setIsLoading(false);
            }
    
        } catch (error) {
            console.error('Error fetching answer:', error);
            setIsLoading(false);
        }
    };
    
    

    return (
        <div className={styles.aichat}>
      <div className={styles.radiogroup}>
    <div>
        <label>
            <input
                type="radio"
                value="1"
                checked={personality === '1'}
                onChange={(e) => setPersonality(e.target.value)}
            />
            <span>GPT</span>
        </label>
    </div>
    <div>
        <label>
            <input
                type="radio"
                value="2"
                checked={personality === '2'}
                onChange={(e) => setPersonality(e.target.value)}
            />
            <span>Pirate</span>
        </label>
    </div>
    <div>
        <label>
            <input
                type="radio"
                value="3"
                checked={personality === '3'}
                onChange={(e) => setPersonality(e.target.value)}
            />
            <span>Goofy</span>
        </label>
    </div>
    <div>
        <label>
            <input
                type="radio"
                value="4"
                checked={personality === '4'}
                onChange={(e) => setPersonality(e.target.value)}
            />
            <span>Smeagol</span>
        </label>
    </div>
    <div>
        <label>
            <input
                type="radio"
                value="5"
                checked={personality === '5'}
                onChange={(e) => setPersonality(e.target.value)}
            />
            <span>Flanders</span>
        </label>
    </div>
</div>

           
<textarea className={styles.answer} readOnly value={answer} placeholder="Answer" />
            
            {/* Predefined question buttons */}
            <div className={styles.prequestions}>
                {predefinedQuestions.map((question, index) => (
                    <button key={index} onClick={() => handlePredefinedQuestionClick(question)}>
                        {question}
                    </button>
                ))}
            </div>
<div className = {styles.questiondiv}>
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ask something about Dennis"
                className={styles.question}
            />
            <button
                className={styles.submitbutton}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? "waiting for AI... <10sec" : "Submit"}
            </button>
            
           
        </div>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </div>
    );
}

export default App;


