
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Section from '../globalfunctions/Section';
import Sidebar from './Sidebar';
import Footer from './Footer';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';
import data from '../data/data.json';

const { sections } = data[0];

function App() {
  const [activeSection, setActiveSection] = useState(sections[0]);

  const handleNavbarClick = (section) => {
    setActiveSection(section);
  };

  return (
    <SectionsContext.Provider value={{ sections, activeSection, handleNavbarClick }}>
      <div className={styles.app}>
        <Router>
          <Header />
          <div className={styles.contentholder}>
          <div className={styles.section}>
            <Routes>
              {sections.map((section, index) => (
                <Route 
                  path={`/${section.title.toLowerCase().replace(/ /g, '-')}`} 
                  key={index} 
                  element={<Section title={section.title} content={section.content} />} 
                />
              ))}
             
              <Route
                path=''
                key={"default"} 
                element={ <div className={styles.section}><Section title={sections[0].title} content={sections[0].content} /></div>}
              />
              
            </Routes>
            </div>
            <Sidebar sections={sections} />
          </div>
          <Footer />
        </Router>
      </div>
    </SectionsContext.Provider>
  );
}

export default App;
