// ProfilePic.js

import React from 'react';
import styles from './App.module.css';

const ProfilePic = ({ src }) => {
  return (
    <img className={styles.profilePic} src={src} alt="Profile" />
  );
};

export default ProfilePic;
