// SkillPage.js

import React from 'react';
import styles from './App.module.css'
import Section from '../globalfunctions/Section';

const SkillPage = ({ skill }) => {
  return (

    <div className={styles.section}>
    <Section title={skill.title} content={skill.content} />
    </div>
  );
};

export default SkillPage;
