import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ sections }) => {
 
  return (
    <div style={{  backgroundColor: '#f2f2f2', padding: '10px', minWidth:"13rem" }}>
      {sections.map((section, index) => (
        <Link key={index} to={`/${section.title.toLowerCase().replace(/ /g, '-')}`} style={{ display: 'block', color: '#0099cc', fontSize:"1.2rem", textDecoration: 'none', padding: '5px 0' }}>
          {section.title}
        </Link>
      ))}
    </div>
  );
}

export default Sidebar;
