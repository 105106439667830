import React, { useState, useRef } from 'react';
import Menu from './Menu';
import SkillPage from './SkillPage';
import data from '../data/data.json';
import styles from './App.module.css';
import SkillsBanner from './SkillsBanner';



const Content = () => {
  
  const sections = data[0].sections;
  const [selectedSkill, setSelectedSkill] = useState(sections[0]);

  const [animation, setAnimation] = useState(false);
  const [showSkill, setShowSkill] = useState(true);
  const timeoutRef = useRef(null);

  const handleSelect = async (skill) => {
    setShowSkill(false);
    setAnimation(true);

    // Clear the previous timeout, if any
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSelectedSkill(skill);
      setAnimation(false);
      setShowSkill(true);
    }, 1500);
  };

  return (
    <>
     
      <Menu items={sections} onSelect={handleSelect} />
      {showSkill && selectedSkill ? <SkillPage skill={selectedSkill}  /> : <div className={styles.loadingpage}>
      
      <div className={animation ? styles.animationActive : styles.animation} />
      <div className={animation ? styles.animationActive : styles.animation} />
      <div className={animation ? styles.animationActive : styles.animation} />
      
      </div>}
      
    </>
  );
};

export default Content;
