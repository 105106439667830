// Menu.js

import React from 'react';
import styles from './App.module.css';

const Menu = ({ items, onSelect }) => {
  return (
    <div className={styles.menu}>
      {items.map((item, index) => (
        <button key={index} onClick={() => onSelect(item)}>{item.title}</button>
      ))}
    </div>
  );
};

export default Menu;
