import React, { useEffect } from 'react';
import backgroundimage from './bgimage.jpg';
import backgroundimagesmall from './bgimagesmol.jpg';

const useBackgroundResize = (ref) => {
  
  // Preload images
  useEffect(() => {
    const imagesToPreload = [backgroundimage, backgroundimagesmall];

    imagesToPreload.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        if (window.outerWidth <= 1070) {
          ref.current.style.backgroundImage = `url(${backgroundimagesmall})`;
        } else {
          ref.current.style.backgroundImage = `url(${backgroundimage})`;
        }
      }
    };
    
    // Call once to set the initial background
    handleResize();

    // Set up the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);
};

export default useBackgroundResize;
