import React, { useEffect, useState } from 'react';
import Header from './Header';
import Section from './Section';
import styles from './App.module.css';
import data from '../data/data.json';

const App = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Set the data from the imported JSON directly
    setSections(data[0]?.sections || []);
  }, []);

  return (
    
    <div className={styles.app}>
      <div className={styles.container}>
      <Header />
      {sections.map((section, index) => (
        <Section key={index} title={section.title} content={section.content} />
      ))}
    </div>
    </div>
  );
};

export default App;
