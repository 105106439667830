import React, { useContext } from 'react';

import Navbar from './Navbar';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';
import ProfilePhoto from './Images';
import Sidebar from './Sidebar';




function Header() {

  const { sections, handleNavbarClick } = useContext(SectionsContext);


  return (
  
    <header className={styles.header}>
      <div className={styles.author}>

      <ProfilePhoto />
        <div className={styles.title}>

Dennis Karlsson<br />
-computer stuff specialist
      </div>
   
      <Navbar sections={sections} onNavbarClick={handleNavbarClick} />
        </div>
      

    </header>
    
  );
}

export default Header;
