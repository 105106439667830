
import React from 'react';
import styles from './App.module.css';

function Section({ title, content }) {
  const paragraphs = content.split('\n').map((line, index) => <p key={index} className={styles.paragraph}>{line}</p>);

  return (
    <section className={styles.section}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      {paragraphs}
    </section>
  );
}

export default Section;
