import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import styles from './App.module.css';

const SkillsCarousel = ({ skills }) => {
  return (
    <Carousel
    showThumbs={false}
      autoPlay={true}
      infiniteLoop
      interval={1900}
      showArrows={false}
    
      showStatus={false}
      className={styles.carousel}
      showIndicators={false}
    >
      {skills.map((skill, index) => (
        <div key={index}>
          <p>{skill}</p>
        </div>
      ))}
    </Carousel>
  )
}

export default SkillsCarousel;
