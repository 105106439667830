import React, { useState, useEffect, useRef } from 'react';
import styles from './App.module.css';
import Section from '../globalfunctions/Section';

const NavBar = ({ sections, onSectionClick }) => {
  const navbarRef = useRef();
 
  const cellRefs = useRef({});

  const [activeSection, setActiveSection] = useState(null);
  const [activeOverlayStyle, setActiveOverlayStyle] = useState({});  // New state variable
  const [textHolderStyle, setTextHolderStyle] = useState({});
  const textHolderRef = useRef(null);
  const [cellOpen, setCellOpen] = useState(false);
  const [originalCellStyle, setOriginalCellStyle]  = useState({});
  const [activeCoverImage, setActiveCoverImage] = useState({})
  const [originalCoverImage, setOriginalCoverImage] = useState({})

  const [activeCoverImageKey, setActiveCoverImageKey] = useState(null);

  const animationtimer = 1.0;



  const handleResize = () => {
    // Get the dimensions of .navbar
    const navbarRect = navbarRef.current.getBoundingClientRect();
    

    // Set the size of .textholder to match .navbar
    setTextHolderStyle({
      position: 'relative',
      top: '0px',
      left: '0px',
      width: `${navbarRect.width}px`,
      height: `100%`, 
      backgroundColor:"none",
      zIndex:"10",
      overflowY:"scroll",
      paddingRight:"0px",
      boxSizing:"border-box"


    });
   
    if (cellOpen!==true) return;

    // Get the rect for the current section
    const menuRect = cellRefs.current[activeSection.title].getBoundingClientRect();
    console.log(menuRect.width)
    const parentRect = navbarRef.current.getBoundingClientRect();

    setActiveCoverImage (prevstate => ({
      ...prevstate,

      top: `0px`,
      left: `0px`,
      width: `${parentRect.width}px`,
      height: `${parentRect.height}px`,
     

    }));
    
  
 
    // Update cover image
    setOriginalCoverImage(prevState => ({
      ...prevState,
      top: `0px`,
      left: "0px",
      width: `${menuRect.width}px`,
      height: `${menuRect.height}px`,
      
    }));
  };

  const handleClick = (section, event, color, coverimage) => {
    if (!activeSection) {
     
      console.log(section.title)
      const menuRect = cellRefs.current[section.title].getBoundingClientRect();

      setActiveSection(section);
      onSectionClick(section);

      const rect = event.currentTarget.getBoundingClientRect();
      const parentRect = event.currentTarget.parentNode.getBoundingClientRect();

      

      // Calculate the initial position and size in percentages
      const top = ((rect.top - parentRect.top) / parentRect.height) * 100;
      const left = ((rect.left - parentRect.left) / parentRect.width) * 100;
      const width = (rect.width / parentRect.width) * 100;
      const height = (rect.height / parentRect.height) * 100;

      
      console.log(menuRect.width)

      setOriginalCoverImage ({
        position: 'absolute',
        top: `0px`,
        left: "0px",
        width: `${menuRect.width}px`,
        height: `${menuRect.height}px`, 
        backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"  ,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
        opacity: "1",
        
  
      });  
      
      setActiveCoverImage ({
        position: 'absolute',
        top: `0px`,
        left: `0px`,
        width: `${parentRect.width}px`,
        height: `${parentRect.height}px`,
        backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"  ,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
        opacity: "0.1",
  
      });

      setActiveOverlayStyle({
        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundColor: color,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,

      });

      setOriginalCellStyle({

        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundColor: color,
        transform: 'translate(0, 0) scale(1, 1)',
        transition: `all ${animationtimer}s ease`,
        
      })
      setActiveCoverImageKey(section.title);
      setCellOpen(true)
      handleResize();

      
    }
  };

  // Add a window resize listener to call handleResize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [cellOpen]);

  const handleClose =() =>{
    setCellOpen(false)
    
    onSectionClick(null);
  
    if (textHolderRef.current) {
      textHolderRef.current.scrollTop = 0;
    }
  };

  // Trigger the transition to the final position, size, and transform after the overlay has been rendered
  useEffect(() => {
    if (cellOpen === true) {
      setActiveOverlayStyle({
        ...activeOverlayStyle,  // Keep the existing properties
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        transform: 'translate(0, 0) scale(1, 1)',
        zIndex: "4",
        
      })
    } else {
     
      setActiveOverlayStyle({
        ...activeOverlayStyle,
        ...originalCellStyle, // Keep the existing properties
      });
  
      const timer = setTimeout(() => {
        setActiveSection(null);
      }, (animationtimer*1000))
  
      return () => {
        clearTimeout(timer); // This clears the timer if the component is unmounted or cellOpen changes
      };
    }
  }, [cellOpen]);



  return (
    <div className={styles.navbar} ref={navbarRef}>
      <div className={styles.navlist}>
        {/* Render the new overlay div here, as a direct child of the grid container */}
     
        {activeSection && (<>
        
            <div
              className={styles.activeOverlay}
              style={activeOverlayStyle}
            >
       { <div className='test' key={activeCoverImageKey} style={cellOpen === true ? { ...activeCoverImage } : { ...originalCoverImage} }></div>
       } 
           
            
           <div 
           className={styles.textholder} 
           ref={textHolderRef}
           style={textHolderStyle}
            >   
           
            
          
           <span className={styles.sectiontitle}>{activeSection.title}</span><br />
              
             <div className={`${cellOpen === true ? styles.fadeintext : styles.fadeouttext}`} >

                
             <div className={styles.sectioncontent}>
                <Section
                
                content={activeSection.content}
                title={"none"}
                Year={"2015"}
                />
                
              <br />
              <p 
                onClick={(event) => {
                  event.preventDefault();
                  handleClose();
                }}
              >
                close
              </p></div> </div></div></div>
              </>
          )}

        {/* Render the grid cells */}
        {sections.map((section, index) => {
          
      

          const size = section["2015"][0].size; // Get the corresponding size
          
          const color = section["2015"][0].color
          const coverimage = section["2015"][0].image
          
    
         
         
          return (

            <React.Fragment key={section.title}> 
            <span 
            ref={ref => cellRefs.current[section.title] = ref}
             style={{
              backgroundColor: color, 
              backgroundImage: coverimage ? `url(${require(`${coverimage}`)})` : "none",
              backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
           
              backgroundPosition: "center"  }}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                handleClick(section, event, color, coverimage);
              }}
              key={section.title}
              className={`${styles.menuitem} ${styles[size]} ${
                activeSection && activeSection === section ? styles.active : ''
              }`} // Add the size class here
            >
         { /*     <div
              className='test'
              style={
                section.title === activeCoverImageKey
                  ? (cellOpen ? { ...activeCoverImage, visibility: 'visible' } : { ...originalCoverImage, visibility: 'visible' })
                  : { visibility: 'visible' }
              }
              
           ></div>
           */
          }
  
              <a>
              
                <span className={styles.sectiontitle}>{section.title}</span>
               
                
              </a>
           
            </span>
            
          </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default NavBar;
