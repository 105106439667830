import React from 'react';

const SkillsBanner = () => {
  const skills = [
    'REACT', 'JS', 'JQUERY', 'NodeJS', 'HTML5', 'WordPress', 'CSS', 'VBA', 
    'Python', 'C++', 'SQL', 'MSSQL', 'Stored Procedures', 'Blockchain technology', 
    'Payment Solutions (XRPL)', 'API integrations', 'SCRUM', 'Team leading', 
    'Recruitment', 'Planning', 'Performance Analysis', 'Staff Training & Onboarding', 
    'Reporting', 'Photography', 'Studio Lighting', 'Post Processing', 'Photoshop', 
    'Lightroom', 'AfterEffects', 'InDesign', 'Color profiling for print & web', 
    'Marketing'
  ];

  return (
    <div className="section" style={{ 
      fontSize: '0.8em', 
      lineHeight: '1.6',
      color: '#fff',
      marginTop: '5px'
    }}>
      {skills.join(' / ')}
    </div>
  );
}

export default SkillsBanner;
