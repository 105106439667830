// App.js
import React, { useState } from 'react';
import styles from './App.module.css';
import Header from './Header';
import NavBar from './NavBar';
import Footer from './Footer';
import data from '..//data/data.json';




const { sections: firstdata } = data[0];  // Rename sections to firstdata during destructuring

const section = [];

const sections = [...firstdata]
/*
{

 
  title: "DENNIS KARLSSON3",
  content: <Header />,
  2015:[{
    "color":"#78a149",
    "size" : "large",
    "image": "./iotmany.png"
  }]
}]
*/

function App() {
  const [currentSection, setCurrentSection] = useState(null);

  
 

  return (
    <div className={styles.app}> 

      
       {/*<Section 
        key={currentSection.title}
        title={currentSection.title}
        content={currentSection.content}
  />*/}
  
        <NavBar sections={sections} onSectionClick={setCurrentSection}/>
      
     
      {<Footer />
      }
    </div>
  );
}

export default App;
