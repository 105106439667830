import React from 'react';
import profilePic from './portrait.gif';
import SkillsBanner from './SkillsBanner';

const Header = () => {
  return (
    <header style={{
      backgroundColor: '#0099cc', 
      color: '#fff', 
      padding: '20px', 
      display: 'flex', 
      alignItems: 'center'
    }}>
      <img src={profilePic} alt="Profile" style={{
        minWidth: '200px', 
      
        
      
        marginRight: '20px'
      }} />
      <div>
        <h1 style={{margin: 0}}>Dennis Karlsson</h1>
        <SkillsBanner />
      </div>
    </header>
  );
}

export default Header;
