import React from "react";
import SkillsCarousel from './SkillsCarousel';

const SkillsBanner = () => {
  const skills = [
    'REACT', 'JS', 'JQUERY', 'NodeJS', 'HTML5', 'WordPress', 'CSS', 'VBA', 
    'Python', 'C++', 'SQL', 'MSSQL', 'Stored Procedures', 'Blockchain technology', 
    'Payment Solutions (XRPL)', 'API integrations', 'SCRUM', 'Team leading', 
    'Recruitment', 'Planning', 'Performance Analysis', 'Staff Training & Onboarding', 
    'Reporting', 'Photography', 'Studio Lighting', 'Post Processing', 'Photoshop', 
    'Lightroom', 'AfterEffects', 'InDesign', 'Color profiling for print & web', 
    'Marketing'
  ];

  const groupedSkills = [];

  for(let i = 0; i < skills.length; i+=4) {
    groupedSkills.push(skills.slice(i, i+4).join(' / '));
  }

  return (
    <div className="section" >
      <SkillsCarousel skills={groupedSkills} />
    </div>
  );
};

export default SkillsBanner;
