import React from 'react';

const Section = ({ title, content, Year }) => {
   
  const id = title.toUpperCase().replace(/\s+/g, "-").replace("&", "%26");


  if(content instanceof Object){
    return(
    <section id={id} >
    {content}
  </section>
  )
   
   }

  const createJSXFromString = (str) => {
    const jsx = [];
    let buffer = "";
    
    for (let i = 0; i < str.length; i++) {
        if (str[i] === '<') {
            if (buffer.trim()) {  // Only push buffer content if it's not just whitespace
                jsx.push(<span key={jsx.length}>{buffer}</span>);
                buffer = "";
            }
            const tagCloseIndex = str.indexOf('>', i);
            const tag = str.substring(i + 1, tagCloseIndex);
            
            if (tag === "b") {
                const closeTagIndex = str.indexOf('</b>', tagCloseIndex);
                jsx.push(<b key={jsx.length}>{str.substring(tagCloseIndex + 1, closeTagIndex)}</b>);
                i = closeTagIndex + 3;
            } else if (tag === "i") {
                const closeTagIndex = str.indexOf('</i>', tagCloseIndex);
                jsx.push(<i key={jsx.length}>{str.substring(tagCloseIndex + 1, closeTagIndex)}</i>);
                i = closeTagIndex + 3;
            } else if (tag.startsWith("h")) {
                const closeTagIndex = str.indexOf(`</h${tag[1]}>`, tagCloseIndex);
                const TagName = `h${tag[1]}`;
                jsx.push(<TagName key={jsx.length}>{str.substring(tagCloseIndex + 1, closeTagIndex)}</TagName>);
                i = closeTagIndex + 3 + tag.length;
            }
        } else if (str[i] === '\n') {
            if (buffer.trim()) {  // Only push buffer content if it's not just whitespace
                jsx.push(<span key={jsx.length}>{buffer}</span>);
                buffer = "";
            }
            jsx.push(<br key={jsx.length} />);
        } else {
            buffer += str[i];
        }
    }
    if (buffer.trim()) {  // Only push buffer content if it's not just whitespace
        jsx.push(buffer);
    }

    if (Year === "2015") {
        return jsx.filter((element, index) => {
            
          if (React.isValidElement(element) && element.type === "h1") {
            // Remove the next item which is the content of the h1 tag
         
           
            jsx.splice(index + 1, 1);
            return false;
          }
          return true;
        });
      }
    
    return jsx;
}

  return (
    <section id={id} >
        <br />
      {createJSXFromString(content)}
    </section>
  );
}

export default Section;
