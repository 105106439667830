
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionsContext from './SectionsContext';
import styles from './App.module.css';

function Navbar() {
  const { sections } = useContext(SectionsContext);
  const [activeSection, setActiveSection] = useState(sections[0]);

  return (
    <nav className={styles.navbar}>
      <ul className={styles.navList}>
        {sections.map((section, index) => (
          <li key={index} className={`${styles.navLink} ${activeSection === section ? styles.liLinkActive : ''}`}>
            <Link onClick={()=>(setActiveSection(section))} to={`/${section.title.toLowerCase().replace(/ /g, '-')}`} 
            className={`${styles.navLink} ${activeSection === section ? styles.navLinkactive : ''}`}>
              {section.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
