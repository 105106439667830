import React from 'react';
import styles from './App.module.css'; // Import the CSS module
import data from '../data/data.json';
import Section from '../globalfunctions/Section';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import SkillsBanner from './SkillsBanner';
import ProfilePhoto from './ProfilePhoto';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const { sections } = data[0];
//console.log(data[0].sections[0].title)
//console.log(data[0].sections[0].content)

function App() {
  return (
    <div className={styles.app}>
      <Router>
        <Header />
        
        <div style={{ display: 'flex', flex:'1'  }}>
          <Sidebar sections={sections} />
          <Routes>
            {sections.map((section, index) => (
             
              <Route 
                path={`/${section.title.toLowerCase().replace(/ /g, '-')}`} 
                key={index} 
                element={<Section title={section.title} content={section.content} />} 
              />
            ))}
            <Route
           
              path=''
              key={"test"} 
              element = {
              <Section title={data[0].sections[0].title} content={data[0].sections[0].content} 
             
              />
             

            }/>
          </Routes>
        </div>
     
        <Footer />
      </Router>
    </div>
  );
}
export default App;
