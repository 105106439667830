// App.js

import React from 'react';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import styles from './App.module.css';

const App = () => {

  return (
    <div className={styles.App}>
      <Header />
      <Content />
      <Footer />
    </div>
  )
}

export default App;
