import React from 'react';

const Menu = ({ sections }) => {
  return (
    <nav style={{ background: 'lime' }}>
      <ul>
        {sections.map((section, index) => (
          <li key={index}>
            <a 
              href={`#${section.title.toUpperCase().replace(/[\s&]+/g, "-").replace("&", "%26")}`} 
              style={{ color: 'blue', fontSize: '1.3rem', fontFamily: 'Comic Sans MS' }}>
                <h2>{section.title}</h2>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Menu;